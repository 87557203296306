/* eslint-disable */
import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  makeStyles
} from '@material-ui/core';
import { useNotify, useTranslate } from 'react-admin';
import { MAXIMUM_ADDED_PLAYERS, MAX_NATIVE_ID_LENGTH } from '../../../../../constant/campaign';
import { formatNumber } from '../../../../../services/util/formatNumber';
import { DeleteRounded, Description, Error } from '@material-ui/icons';
import { INVALID_PLAYER_FILE_CODE, convertXlsxFilesToPlayerList, downloadInvalidFile, formatBytes } from '../../utils';

const useStyles = makeStyles(theme => ({
  downloadButton: {
    color: theme.palette.error.dark,
    padding: 4,
    margin: 0,
    textTransform: "normal"
  }
}))

const FileUploader = ({ setData }) => {
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState();
  const [error, setError] = useState()

  const handleUploadFiles = async (e) => {
    setIsLoading(true)
    setError(false)
    try {
      const file = e.target.files[0];

      const players = await convertXlsxFilesToPlayerList(file)

      setFile({ name: file?.name, size: file?.size })
      setData(players);
    } catch (error) {
      console.log(error.data)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  };

  const onRemoveUploadedFile = () => {
    setData([])
    setFile()
  }

  return ( 
    <Box display="flex" flexDirection="column" sx={{ gap: 12 }} mt={2} mb={4}>
    <Box sx={{display:"flex", gap: 8, alignItems: "center" }}>
            {file ? <FilePreview file={file} onDelete={onRemoveUploadedFile}/> : <><Button variant="outlined" component="label" color="primary" disabled={isLoading}>
              {translate('ra.action.chooseFile')}
              <Box component="input" type="file" accept=".csv, .xlsx, .xls" hidden onChange={handleUploadFiles} />
            </Button>
            <Box sx={{fontSize: 12, fontStyle: "italic"}}>
              <Box color="rgba(0, 0, 0, 0.6)">{`(${translate('resources.prepaid.allowedFileTypes')})`}</Box>
              <Box component="a" href="/template/sample-player-list.xlsx" target="_blank" download>{translate('resources.campaign.download-file-format')}</Box>
              </Box></>}
          </Box>
      {!!error && <InvalidPlayerFileMessage code={error?.code} data={error?.data} message={error?.message}/>}
    </Box>
          
  );
};

export default FileUploader;

const FilePreview = ({onDelete, file = {}}) => {
  const theme = useTheme()
  return <Box width="100%" py={1} px={2} borderRadius={8} alignItems="center" bgcolor={theme.palette.grey[100]} border={`1px dashed ${theme.palette.grey[600]}`} display="flex" sx={{ gap: 10 }}>
  <Description/>
  <Box flexGrow={1}>
  <Typography variant="body2">{file?.name}</Typography>
    <Typography variant="body2">{formatBytes(file?.size)}</Typography>
  </Box>
    
    <Box
      component={IconButton}
      size="small"
      color="primary"
      sx={{ width: 'fit-content' }}
      onClick={onDelete}
    >
      <DeleteRounded fontSize="small" />
    </Box>
  </Box>
}

const InvalidPlayerFileMessage = ({ code, data, message: defaultMessage }) => {
  const theme = useTheme()
  const t = useTranslate();
  const notify = useNotify()
  const classes = useStyles()

  const message = useMemo(() => {
    if (code === INVALID_PLAYER_FILE_CODE.CONTAIN_INVALID_PLAYERS) {
      return t('resources.campaign.validation.file-contain-invalid-players', { totalInvalid: formatNumber("en", data?.totalInvalidPlayers), maxCharacters: formatNumber("en", MAX_NATIVE_ID_LENGTH)  })
    } else if (code === INVALID_PLAYER_FILE_CODE.EXCEED_TOTAL_ALLOWED_PLAYERS) {
      return t('resources.campaign.validation.file-exceed-maximum-players', { totalPlayers: formatNumber("en", data?.totalPlayers), maxPlayers: formatNumber("en", MAXIMUM_ADDED_PLAYERS)})
    }
    return defaultMessage || t('resources.campaign.validation.invalid-file')
  }, [t, code, data, defaultMessage])

  const onDownloadInvalidFile = () => {
    try {
      downloadInvalidFile(data.wb)
    } catch (error) {
      notify(error.message, "error")
    }
  }

  return <Box width="100%" p={2} borderRadius={8} bgcolor="#f4cbcc" color={theme.palette.error.dark} border={`1px solid ${theme.palette.error.dark}`} display="flex" sx={{ gap: 4 }}>
    <Error/>
    <Box display="flex" flexDirection="column" sx={{ gap: 10 }}>
      {message}
      {code === INVALID_PLAYER_FILE_CODE.CONTAIN_INVALID_PLAYERS && 
        <Button className={classes.downloadButton} disableElevation size="small" startIcon={<Description/>} onClick={onDownloadInvalidFile}>{'invalid_file.xlsx'}</Button> 
      }
    </Box>
  </Box>
}
