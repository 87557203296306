import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WealthInputWrapper from '../guesser/wealth-input-wrapper';

const useStyles = makeStyles({
  infoButton: {
    padding: '0 0 0 4px',
    '&:hover': {
      background: 'transparent',
    },
    '& svg': {
      width: 18,
      height: 18,
    },
  },
  label: {
    pointerEvents: 'auto',
    cursor: 'text',
  },
});

const InputWithTooltipWrapper = ({
  children, title, tooltip, required, ...otherProps
}) => {
  const classes = useStyles();

  return (
    <WealthInputWrapper
      label={(
        <Box display="flex">
          <Box component="span">{`${title}${required ? '*' : ''}`}</Box>
          <Tooltip
            placement="top-end"
            title={tooltip}
          >
            <IconButton className={classes.infoButton}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      InputLabelProps={{
        className: classes.label,
        required: false,
      }}
      required={required}
      {...otherProps}
    >
      {children}
    </WealthInputWrapper>
  );
};

InputWithTooltipWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default InputWithTooltipWrapper;
