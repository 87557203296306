/* eslint-disable no-use-before-define, no-param-reassign */
import { Box, Button, Drawer, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, makeStyles } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useRefresh, useTranslate } from 'react-admin';
import { Close } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Form } from 'antd';
import moment from 'moment';
import { showConfirmDialog } from '../../../../../services/redux/app/app.actions';
import FileUploader from './FileUploader';
import { formatNumber } from '../../../../../services/util/formatNumber';
import { MAXIMUM_ADDED_PLAYERS } from '../../../../../constant/campaign';
import ManualAdding from './ManualAdding';
import IssuedDate from './IssuedDate';
import { useAddCampaignPlayers } from '../../utils';
import GameSelection from './GameSelection';
import FreeSpinExpireUnit from './FreeSpinExpireUnit';
import FreeSpinsPerPlayer from './FreeSpinsPerPlayer';
import FreeSpinExpireIn from './FreeSpinExpireIn';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  addPlayersBtn: {
    width: 'fit-content',
    marginLeft: 'auto',
    fontSize: 12,
    padding: '6px 8px',
    '&,&:hover': {
      boxShadow: 'none',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '1.5rem',
    marginBottom: '15px',
  },
  paper: {
    padding: 24,
    width: 700,
    maxWidth: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },
  methodWrapper: {
    flexDirection: 'row',
  },
  banner: {
    background: theme.palette.warning.light,
    margin: '0px -24px 8px',
    padding: '12px 24px',
    color: theme.palette.warning.dark,
  },
  warningBanner: {
    background: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  infoIcon: {
    width: 14,
    height: 14,
  },
}));

const UpdatePlayerDrawer = ({
  campaignId, onClose, campaignData,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const t = useTranslate();
  const refresh = useRefresh();
  const classes = useStyles();
  const [manualPlayers, setManualPlayers] = useState([]);
  const [uploadedPlayers, setUploadedPlayers] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState('upload');

  const players = useMemo(() => {
    switch (selectedMethod) {
      case 'upload':
        return uploadedPlayers;
      case 'select':
      default:
        return manualPlayers;
    }
  }, [selectedMethod, uploadedPlayers, manualPlayers]);

  const {
    addPlayers, isAdding,
  } = useAddCampaignPlayers(campaignId);

  const handleAddConfirm = values => {
    dispatch(
      showConfirmDialog({
        isOpen: true,
        title: !values.issuedDate ? 'resources.campaign.add-player-without-issued-date-title' : 'resources.campaign.add-player-title',
        content: !values.issuedDate ? 'resources.campaign.add-player-without-issued-date-desc' : 'resources.campaign.add-player-desc',
        onConfirm: () => handleAdd(values),
      }),
    );
  };

  const handleAdd = async values => {
    addPlayers({
      players,
      issuedDate: values.issuedDate,
      betAmount: values.betAmount,
      games: values.games,
      freeSpinExpireIn: values.freeSpinExpireIn,
      freeSpinExpireUnit: values.freeSpinExpireUnit,
      freeSpinsPerPlayer: values.freeSpinsPerPlayer,
    }, {
      onSuccess: () => {
        refresh();
        onClose?.();
      },
    });
  };

  const handleMethodChange = event => {
    setSelectedMethod(event.target.value);
  };

  const handleClose = () => {
    dispatch(
      showConfirmDialog({
        isOpen: true,
        title: 'resources.campaign.cancel-title',
        content: 'resources.campaign.cancel-desc',
        onConfirm: onClose,
      }),
    );
  };

  return (
    <Drawer
      open
      className={classes.root}
      anchor="right"
      classes={{
        paper: classes.paper,
      }}
    >
      <IconButton
        className={classes.closeButton}
        onClick={handleClose}
      >
        <Close />
      </IconButton>
      <Form
        form={form}
        onFinish={handleAddConfirm}
        className={classes.form}
      >
        <Box className={classes.title}>{t('resources.campaign.add-players')}</Box>
        <Box
          className={clsx(classes.banner, {
            [classes.warningBanner]: players.length >= MAXIMUM_ADDED_PLAYERS,
          })}
        >
          {t('resources.campaign.maximum-players', {
            maximumPlayers: formatNumber('en', MAXIMUM_ADDED_PLAYERS),
            totalCurrentPlayers: formatNumber('en', players.length),
          })}
        </Box>
        <Box
          flexGrow={1}
          overflow="scroll"
        >
          <FormControl>
            <RadioGroup
              value={selectedMethod}
              onChange={handleMethodChange}
              className={classes.methodWrapper}
            >
              <FormControlLabel
                value="upload"
                control={<Radio color="primary" />}
                label={t('resources.campaign.upload-file')}
              />
              <FormControlLabel
                value="select"
                control={<Radio color="primary" />}
                label={t('resources.campaign.select-players')}
              />
            </RadioGroup>
          </FormControl>
          <Box my={2}>
            <Box hidden={selectedMethod !== 'upload'}>
              <FileUploader
                setData={setUploadedPlayers}
                data={uploadedPlayers}
                campaignData={campaignData}
              />
            </Box>
            <Box hidden={selectedMethod !== 'select'}>
              <ManualAdding
                setData={setManualPlayers}
                data={manualPlayers}
                campaignData={campaignData}
              />
            </Box>
          </Box>
          {selectedMethod === 'upload' && (
            <FreeSpinsPerPlayer
              totalUploadedPlayers={uploadedPlayers.length}
              campaignStock={campaignData?.stock}
            />
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              gap: 12,
            }}
            mt={9.5}
            mb={3}
          >
            <GameSelection
              form={form}
              brandId={campaignData?.brandId}
              currencyId={campaignData?.currencyId}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              gap: 24,
            }}
          >
            <Box
              display="flex"
              alignItems="flex-end"
              sx={{
                gap: 8,
              }}
            >
              <FreeSpinExpireIn initialValue={campaignData?.freeSpinExpireIn} />
              <Form.Item
                name="freeSpinExpireUnit"
                rules={[{
                  required: true,
                  message: t('ra.validation.required'),
                }]}
                initialValue={campaignData?.freeSpinExpireUnit}
              >
                <FreeSpinExpireUnit />
              </Form.Item>
            </Box>
            <Box flexGrow={1}>
              <Form.Item
                name="issuedDate"
                getValueProps={i => ({
                  value: i ? moment(i) : undefined,
                })}
                initialValue={campaignData?.startTime && moment(campaignData?.startTime).isAfter(moment()) ? moment(campaignData?.startTime).startOf('hour') : undefined}
              >
                <IssuedDate campaignData={campaignData} />
              </Form.Item>
            </Box>

          </Box>

        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          bgcolor="white"
          mt={3}
        >
          <Button
            onClick={handleClose}
            variant="outlined"
          >
            {t('ra.action.cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            disabled={!players?.length || isAdding}
            type="submit"
          >
            {t('ra.action.save')}
          </Button>
        </Box>
      </Form>
    </Drawer>
  );
};

export default UpdatePlayerDrawer;
