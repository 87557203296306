import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { PLAYER_BATCH_SOURCE_TYPE, PLAYER_BATCH_STATUS } from '../../../../../constant/campaign';
import CustomizedFilterWrapper from '../../../../../base/components/customized/CustomizedFilterWrapper';
import DateRangePickerInput from '../../../../../base/components/guesser/date-range-input';
import { TextInput } from '../../../../../base/components/ra/inputs';

const PlayerUpdateBatchFilterWrapper = () => {
  const t = useTranslate();
  const { id } = useParams();

  return (
    <CustomizedFilterWrapper>
      <DateRangeInput
        source="created"
        label={t('resources.campaign.fields.created-date')}
        resource={`campaign/${id}/player-batch`}
      />
      <DateRangeInput
        source="scheduledAt"
        label={t('resources.campaign.fields.scheduled-date')}
        resource={`campaign/${id}/player-batch`}
      />
      <TextInput
        source="batchCode||$eq"
        label="resources.campaign.fields.player-batch-code"
        resettable
      />
      <SelectInput
        label="resources.campaign.fields.source-type"
        source="source"
        choices={Object.values(PLAYER_BATCH_SOURCE_TYPE).map(item => ({
          id: item,
          name: t(`resources.campaign.player-batch-source-type.${item}`),
        }))}
        resettable
        variant="outlined"
        options={{
          SelectProps: {
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          },
        }}
      />
      <SelectInput
        label="resources.campaign.fields.status"
        source="status"
        choices={Object.values(PLAYER_BATCH_STATUS).map(item => ({
          id: item,
          name: t(`resources.campaign.player-batch-status.${item}`),
        }))}
        resettable
        variant="outlined"
        options={{
          SelectProps: {
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          },
        }}
      />
    </CustomizedFilterWrapper>
  );
};

const DateRangeInput = props => (
  <DateRangePickerInput
    prefix
    ranges={[]}
    {...props}
  />
);

export default PlayerUpdateBatchFilterWrapper;
