import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { CAMPAIGN_PLAYER_STATUS } from '../../../../constant/campaign';
import CustomizedFilterWrapper from '../../../../base/components/customized/CustomizedFilterWrapper';
import DateRangePickerInput from '../../../../base/components/guesser/date-range-input';
import resourceSlug from '../../../../constant/resource-slug';
import { TextInput } from '../../../../base/components/ra/inputs';

const CampaignPlayerFilterWrapper = () => {
  const t = useTranslate();
  const { id } = useParams();

  return (
    <CustomizedFilterWrapper>
      <DateRangeInput
        source="issuedDate"
        label={t('resources.campaign.fields.issued-date')}
        resource={`${resourceSlug.MKT_FREE_SPINS}/${id}/players`}
      />
      <DateRangeInput
        source="usedDate"
        label={t('resources.campaign.fields.claimed-date')}
        resource={`${resourceSlug.MKT_FREE_SPINS}/${id}/players`}
      />
      <TextInput
        source="playerId||$eq"
        label="resources.campaign.fields.player-id"
        resettable
      />
      <TextInput
        source="batchCode||$eq"
        label="resources.campaign.fields.player-batch-code"
        resettable
      />
      <SelectInput
        label="resources.campaign.fields.status"
        source="status"
        choices={Object.values(CAMPAIGN_PLAYER_STATUS).map(item => ({
          id: item,
          name: t(`resources.campaign.player-status.${item}`),
        }))}
        resettable
        variant="outlined"
        options={{
          SelectProps: {
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          },
        }}
      />
    </CustomizedFilterWrapper>
  );
};

const DateRangeInput = props => (
  <DateRangePickerInput
    prefix
    ranges={[]}
    {...props}
  />
);

export default CampaignPlayerFilterWrapper;
