/* eslint-disable no-case-declarations */
import { CACHE_CAMPAIGNS_BY_PAGE,
  CACHE_CAMPAIGN_ACTION_LOG_BY_PAGE,
  CACHE_CAMPAIGN_PLAYERS_BY_PAGE,
  CACHE_CAMPAIGN_PLAYER_BATCH_BY_PAGE,
  UPDATE_CURRENT_CAMPAIGN_ACTION_LOG_PAGE,
  UPDATE_CURRENT_CAMPAIGN_PAGE,
  UPDATE_CURRENT_CAMPAIGN_PLAYER_PAGE,
  UPDATE_CURRENT_CAMPAIGN_PLAYER_BATCH_PAGE } from '../root.actions';

const initialCampaignStates = {
  campaignList: {},
  currentCampaignPage: 1,
  actionLogList: {},
  currentActionLogPage: 1,
  playerList: {},
  currentPlayerPage: 1,
  playerBatchList: {},
  currentPlayerBatchPage: 1,
};

export const campaignReducer = (state = initialCampaignStates, action) => {
  const newPage = action.payload?.page || 1;

  switch (action.type) {
    case CACHE_CAMPAIGNS_BY_PAGE:
      return {
        ...state,
        currentCampaignPage: newPage,
        campaignList:
          newPage === 1
            ? {
              1: action.payload?.data,
            }
            : {
              ...state.campaignList,
              [newPage]: action.payload?.data,
            },
      };
    case UPDATE_CURRENT_CAMPAIGN_PAGE:
      return {
        ...state,
        currentCampaignPage: action.payload,
      };
    case CACHE_CAMPAIGN_PLAYERS_BY_PAGE:
      return {
        ...state,
        currentPlayerPage: newPage,
        playerList:
          newPage === 1
            ? {
              1: action.payload?.data,
            }
            : {
              ...state.playerList,
              [newPage]: action.payload?.data,
            },
      };

    case UPDATE_CURRENT_CAMPAIGN_PLAYER_PAGE:
      return {
        ...state,
        currentPlayerPage: action.payload,
      };
    case CACHE_CAMPAIGN_ACTION_LOG_BY_PAGE:
      return {
        ...state,
        currentActionLogPage: newPage,
        actionLogList:
            newPage === 1
              ? {
                1: action.payload?.data,
              }
              : {
                ...state.actionLogList,
                [newPage]: action.payload?.data,
              },
      };

    case UPDATE_CURRENT_CAMPAIGN_ACTION_LOG_PAGE:
      return {
        ...state,
        currentActionLogPage: action.payload,
      };
    case CACHE_CAMPAIGN_PLAYER_BATCH_BY_PAGE:
      return {
        ...state,
        currentPlayerBatchPage: newPage,
        playerBatchList:
              newPage === 1
                ? {
                  1: action.payload?.data,
                }
                : {
                  ...state.playerBatchList,
                  [newPage]: action.payload?.data,
                },
      };

    case UPDATE_CURRENT_CAMPAIGN_PLAYER_BATCH_PAGE:
      return {
        ...state,
        currentPlayerBatchPage: action.payload,
      };
    default:
      return state;
  }
};
