import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { SelectInput } from '../../../../../base/components/ra/inputs';
import { CAMPAIGN_UNIT_EXPIRE_IN } from '../../../../../constant/campaign';

const FreeSpinExpireUnitInput = props => {
  const t = useTranslate();

  const issuedExpirationChoices = useMemo(
    () => Object.values(CAMPAIGN_UNIT_EXPIRE_IN).map(unit => ({
      id: unit,
      name: t(`resources.campaign.unit.${unit}`),
    })),
    [t],
  );

  return (
    <SelectInput
      choices={issuedExpirationChoices}
      source="freeSpinExpireUnit"
      label={false}
      isRequired
      fullWidth
      style={{
        minWidth: '100%',
      }}
      defaultValue={CAMPAIGN_UNIT_EXPIRE_IN.DAYS}
      {...props}
    />
  );
};

export default FreeSpinExpireUnitInput;
