import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useListContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import InfinitePagination from '../../../../base/components/ra/list/InfinitePagination';
import resourceSlug from '../../../../constant/resource-slug';
import { sanitizeObject } from '../../../../services/util';
import { cacheCampaignPlayersByPage, updateCurrentCampaignPlayerPage } from '../../../../services/redux/campaign/campaign.action';

const CampaignPlayerPagination = () => {
  const {
    playerList, currentPlayerPage,
  } = useSelector(({ campaign }) => campaign);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const {
    filterValues, currentSort, perPage,
  } = useListContext();
  const { id } = useParams();

  const disableNext = useMemo(() => !playerList?.[currentPlayerPage]?.nextPagination, [currentPlayerPage, playerList]);

  const handlePageChange = useCallback(
    async newPage => {
      try {
        if (!playerList[newPage]) {
          const nextPagination = playerList?.[newPage - 1]?.nextPagination;
          const data = await dataProvider.getList(`${resourceSlug.MKT_FREE_SPINS}/${id}/players`,
            {
              pagination: {
                perPage,
                cachedPage: newPage,
              },
              sort: currentSort,
              filter: {
                ...filterValues,
                q: sanitizeObject({
                  nextPagination: JSON.stringify(nextPagination),
                }),
              },
            });

          dispatch(
            cacheCampaignPlayersByPage({
              data,
              page: newPage,
            }),
          );
        }
        dispatch(updateCurrentCampaignPlayerPage(newPage));
      } catch (error) {
        // Do nothing
      }
    },
    [playerList, currentPlayerPage],
  );

  return (
    <InfinitePagination
      disableNext={disableNext}
      disablePrev={currentPlayerPage === 1}
      onNextPage={() => {
        handlePageChange(currentPlayerPage + 1);
      }}
      onPrevPage={() => {
        handlePageChange(currentPlayerPage - 1);
      }}
      currentPage={currentPlayerPage}
    />
  );
};

export default CampaignPlayerPagination;
