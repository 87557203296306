import { Drawer, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import { FunctionField, Labeled, RecordContext, SelectField, TextField, useGetOne, useRedirect, useTranslate } from 'react-admin';
import { useParams, useLocation } from 'react-router-dom';
import ReactJson from 'react-json-view';
import { Close } from '@material-ui/icons';
import resourceSlug from '../../../../constant/resource-slug';
import { ShowSection } from '../../../../base/components/guesser/wealth-show.guesser';
import { CUSTOM_ROUTES } from '../../../../constant/customRoutes';
import { ACTION_MODULE } from '../../../../constant/campaign';
import { DateField } from '../../../../base/components/ra/fields';

const useStyles = makeStyles({
  wrapper: {
    padding: '36px 28px',
    "& > [class*='sectionTitle']": {
      fontSize: 17,
    },
  },
  row: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 250px)',
    gap: 18,
    '& .ra-field': {
      width: '100%',
    },
    gridTemplateAreas: `
    "one two"
    "three four"
    "five five"
    `,
    '& > :nth-child(1)': {
      gridArea: 'one',
    },
    '& > :nth-child(2)': {
      gridArea: 'two',
    },
    '& > :nth-child(3)': {
      gridArea: 'three',
    },
    '& > :nth-child(4)': {
      gridArea: 'four',
    },
    '& > :nth-child(5)': {
      gridArea: 'five',
    },
  },
  root: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
});

const ChangeLogDetailDrawer = () => {
  const t = useTranslate();
  const {
    actionId, id,
  } = useParams();
  const { search } = useLocation();
  const { data } = useGetOne(`${resourceSlug.MKT_FREE_SPINS}/${id}/${resourceSlug.ACTION_LOG}`, actionId);
  const redirect = useRedirect();
  const classes = useStyles();

  const onClose = () => {
    redirect(CUSTOM_ROUTES.CAMPAIGN_CHANGES_LOG.replace(':id', id) + search);
  };

  return (
    <Drawer
      className={classes.root}
      open
      anchor="right"
      onClose={() => onClose()}
    >
      <IconButton
        className={classes.closeButton}
        onClick={() => onClose()}
      >
        <Close />
      </IconButton>
      <RecordContext.Provider value={data}>
        <ShowSection
          className={classes.wrapper}
          classes={{
            root: classes.row,
          }}
          title={t('resources.campaign.general-info')}
        >
          <Labeled label="resources.campaign.fields.created">
            <DateField
              source="created"
            />
          </Labeled>
          <SelectField
            source="module"
            label="resources.campaign.fields.module"
            choices={Object.values(ACTION_MODULE).map(module => ({
              id: module,
              name: t(`resources.campaign.module.${module}`),
            }))}
          />
          <TextField
            source="actor"
            label="resources.campaign.fields.created-by"
          />
          <TextField
            source="actionType"
            label="resources.campaign.fields.action"
          />
          <FunctionField
            label="resources.campaign.fields.payload"
            render={record => (
              <ReactJson
                src={record.payload}
                name={null}
                collapsed
                enableClipboard
                displayDataTypes={false}
              />
            )}
          />
        </ShowSection>
      </RecordContext.Provider>

    </Drawer>
  );
};

export default ChangeLogDetailDrawer;
