import { Box, IconButton } from '@material-ui/core';
import { FileCopy, Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';

const SecretKey = ({ record }) => {
  const [hidden, setHidden] = useState(true);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        {hidden ? Array.from({
          length: record.secretKey?.length || 0,
        }, () => '*').join('') : record.secretKey}
      </Box>
      {!!record.secretKey && (
        <Box
          display="flex"
          sx={{
            gap: 4,
          }}
        >
          <IconButton
            disableRipple
            size="small"
            onClick={() => setHidden(!hidden)}
          >
            {hidden ? <Visibility /> : <VisibilityOff />}
          </IconButton>
          <IconButton
            disableRipple
            size="small"
            onClick={e => {
              e.preventDefault();
              navigator.clipboard.writeText(record.secretKey);
            }}
          >
            <FileCopy />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default SecretKey;
