import { Box, FormControl, OutlinedInput, Select, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(({
  error: {
    '& *[class*="notchedOutline"]': {
      borderColor: 'red',
    },
  },
  label: {
    '& legend': {
      width: '0 !important',
    },
  },
}));

const MuiSelect = ({
  label, children, wrapperProps, required, ...props
}) => {
  const classes = useStyles();
  const error = props?.['aria-invalid'] === 'true';

  return (
    <Box
      component={FormControl}
      position="relative"
      mt={2}
      {...wrapperProps}
      className={clsx(classes.label, error && classes.error, wrapperProps?.className)}
    >
      {label && (
        <Box
          component="label"
          px="6px"
          top="-7px"
          left="9px"
          fontSize={14}
          lineHeight={1}
          fontFamily="Normal"
          sx={{
            color: '#00000099',
          }}
          position="absolute"
          zIndex={1}
          bgcolor="white"
        >
          {`${label}${required ? ' *' : ''}`}
        </Box>
      )}
      <Select
        input={(
          <OutlinedInput
            label=""
          />
        )}
        {...props}
      >
        {children}
      </Select>
    </Box>
  );
};

export default MuiSelect;
