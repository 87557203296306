/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const MultilineAbleCopyField = props => {
  const {
    record, source,
  } = props;

  const [copied, setCopied] = useState(false);
  const textAreaRef = useRef('');
  const value = record?.[source];

  const copyToClipboard = e => {
    setCopied(true);
    textAreaRef.current.select();

     /* For mobile devices */
    textAreaRef.current.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(textAreaRef.current.value);
  };

  useEffect(() => {
    let timeoutCopy = '';
    if (copied) {
      timeoutCopy = setTimeout(() => setCopied(false), 400);
    }

    return () => {
      clearTimeout(timeoutCopy);
    };
  }, [copied]);
  

  return !!value && (
    <Box display="flex" alignItems="center">
      <Box marginRight="16px">{value}</Box>
      <form
        style={{
          width: 0,
          height: 0,
          background: 'transparent',
          overflow: 'hidden',
        }}
      >
        <textarea
          ref={textAreaRef}
          value={value}
        />
      </form>
      <IconButton
        disableRipple
        size="small"
        onClick={copyToClipboard}
      >
        <FileCopyIcon />
      </IconButton>
    </Box>
  );
};

MultilineAbleCopyField.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
  source: PropTypes.string.isRequired,
};

MultilineAbleCopyField.defaultProps = {
  record: {},
};

export default MultilineAbleCopyField;
