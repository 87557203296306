import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useListContext } from 'react-admin';
import InfinitePagination from '../../../base/components/ra/list/InfinitePagination';
import resourceSlug from '../../../constant/resource-slug';
import { sanitizeObject } from '../../../services/util';
import { cacheCampaignsByPage, updateCurrentCampaignPage } from '../../../services/redux/campaign/campaign.action';

const CampaignPagination = () => {
  const {
    campaignList, currentCampaignPage,
  } = useSelector(({ campaign }) => campaign);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const {
    filterValues, currentSort, perPage,
  } = useListContext();

  const disableNext = useMemo(() => !campaignList?.[currentCampaignPage]?.nextPagination, [currentCampaignPage, campaignList]);

  const handlePageChange = useCallback(
    async newPage => {
      try {
        if (!campaignList[newPage]) {
          const nextPagination = campaignList?.[newPage - 1]?.nextPagination;
          const data = await dataProvider.getList(resourceSlug.MKT_FREE_SPINS, {
            pagination: {
              perPage,
              cachedPage: newPage,
            },
            sort: currentSort,
            filter: {
              ...filterValues,
              q: sanitizeObject({
                nextPagination: JSON.stringify(nextPagination),
              }),
            },
          });

          dispatch(
            cacheCampaignsByPage({
              data,
              page: newPage,
            }),
          );
        }
        dispatch(updateCurrentCampaignPage(newPage));
      } catch (error) {
        // Do nothing
      }
    },
    [campaignList, currentCampaignPage],
  );

  return (
    <InfinitePagination
      disableNext={disableNext}
      disablePrev={currentCampaignPage === 1}
      onNextPage={() => {
        handlePageChange(currentCampaignPage + 1);
      }}
      onPrevPage={() => {
        handlePageChange(currentCampaignPage - 1);
      }}
      currentPage={currentCampaignPage}
    />
  );
};

export default CampaignPagination;
