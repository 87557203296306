/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { FunctionField, ReferenceField, SelectField, TextField, useRedirect, useTranslate } from 'react-admin';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { FilterNoneOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import { DateField } from '../../base/components/ra/fields';
import CustomizedList from '../../base/components/customized/CustomizedList';
import resourceSlug from '../../constant/resource-slug';
import CustomLinkField from '../../base/components/ra/fields/customLink.field';
import { transformIdValue } from '../../services/util/stringUtil';
import CampaignListAction from './components/CampaignActions';
import CampaignFilterWrapper from './components/CampaignFilters';
import { CAMPAIGN_ISSUERS } from '../../constant/campaign';
import CampaignStatus from './components/CampaignStatus';
import CampaignPagination from './components/CampaignPagination';

const CampaignList = props => {
  const t = useTranslate();
  const redirect = useRedirect();
  const {
    campaignList, currentCampaignPage,
  } = useSelector(({ campaign }) => campaign);

  const campaigns = useMemo(() => campaignList[currentCampaignPage]?.data || [], [campaignList, currentCampaignPage]);

  return (
    <CustomizedList
      {...props}
      actions={<CampaignListAction />}
      filters={<CampaignFilterWrapper />}
      disabledAutoRefetch
      hasList
      exporter={false}
      controllerProps={{
        data: keyBy(campaigns, 'id'),
        ids: campaigns?.map(({ id }) => id),
      }}
      pagination={<CampaignPagination />}
    >
      <CustomLinkField
        source="id"
        label="common.fields.id"
        href={record => `/#/${resourceSlug.MKT_FREE_SPINS}/${record.id}/show`}
        title={record => transformIdValue(record.id)}
      />
      <TextField
        source="name"
        label="resources.campaign.fields.name"
        sortable={false}
      />
      <TextField
        source="code"
        label="resources.campaign.fields.code"
        sortable={false}
      />
      <DateField
        source="startTime"
        label="resources.campaign.fields.start-date"
        sortable={false}
      />
      <DateField
        source="endTime"
        label="resources.campaign.fields.end-date"
        sortable={false}
      />
      <ReferenceField
        source="groupId"
        label="resources.group.name"
        reference={resourceSlug.GROUP}
        link="show"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="brandId"
        label="resources.brand.name"
        reference={resourceSlug.BRAND}
        link="show"
        sortable={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <SelectField
        source="issuer"
        label="resources.campaign.fields.issuer"
        choices={Object.values(CAMPAIGN_ISSUERS).map(issuer => ({
          id: issuer,
          name: t(`resources.campaign.issuer.${issuer}`),
        }))}
        sortable={false}
      />
      <CampaignStatus
        label="resources.campaign.fields.status"
        sortable={false}
      />
      <FunctionField
        label="ra.field.action"
        sortable={false}
        render={record => (
          <Box
            display="flex"
            gap={4}
            justifyContent="center"
          >
            <Tooltip
              title={t('ra.action.duplicate', {
                _: 'Duplicate',
              })}
            >
              <IconButton
                onClick={() => redirect(`/${resourceSlug.MKT_FREE_SPINS}/create?cloneId=${record.id}`)}
              >
                <FilterNoneOutlined />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />

    </CustomizedList>
  );
};

export default CampaignList;
