import React from 'react';
import { useTranslate } from 'react-admin';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/Inbox';

function EmptyTable(props) {
  const translate = useTranslate();
  return (
    <Box
      p={8}
      pb={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      color="#9e9e9e"
      {...props}
    >
      <InboxIcon fontSize="large" />
      <Typography>{translate('ra.text.noRecords')}</Typography>
    </Box>
  );
}

export default EmptyTable;
