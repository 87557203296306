import { Form, InputNumber } from 'antd';
import { useTranslate } from 'react-admin';
import React from 'react';
import { formatNumber } from '../../../../../services/util/formatNumber';
import AntdInput from '../../../../../base/components/antd/AntdInput';

const FreeSpinsPerPlayer = ({
  campaignStock, totalUploadedPlayers,
}) => {
  const t = useTranslate();

  return (
    <Form.Item
      name="freeSpinsPerPlayer"
      rules={[{
        required: true,
        message: t('ra.validation.required'),
      },
      ({ getFieldValue }) => ({
        validator() {
          const currentFreeSpins = getFieldValue('freeSpinsPerPlayer') || 0;
          const maxFreeSpins = campaignStock ?? Infinity;
          const totalAddedFreeSpins = totalUploadedPlayers * currentFreeSpins;
          const maxAddedFreeSpins = Math.floor(maxFreeSpins / totalUploadedPlayers);

          if (totalAddedFreeSpins > maxFreeSpins) {
            return Promise.reject(new Error(t('resources.campaign.validation.max-value', {
              max: formatNumber('en', maxAddedFreeSpins),
            })));
          }
          return Promise.resolve();
        },
      }),
      ]}
    >
      <AntdInput
        width="100%"
        label={t('resources.campaign.fields.free-spins-quantity')}
      >
        <InputNumber
          step={1}
          precision={0}
          placeholder={t('resources.campaign.fields.free-spins-quantity-placeholder')}
        />
      </AntdInput>
    </Form.Item>
  );
};

export default FreeSpinsPerPlayer;
