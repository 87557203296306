import { Box, IconButton, makeStyles } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Form } from 'antd';
import React from 'react';
import { uniqueId } from 'lodash';
import { MAXIMUM_ADDED_PLAYERS } from '../../../../../constant/campaign';
import EditablePlayersTable, { FreeSpinsInput, NativeIdInput } from './EditablePlayersTable';

const useStyles = makeStyles(({
  addButton: {
    width: 30,
    height: 30,

  },
}));

const ManualAdding = ({
  data, setData, campaignData,
}) => {
  const [form] = Form.useForm();
  const classes = useStyles();

  const handleAddPlayers = values => {
    setData([{
      ...values,
      id: uniqueId(),
    }, ...data]);
    form.resetFields(['nativeId', 'freeSpins']);
  };

  return (
    <Box>
      <Form
        form={form}
        onFinish={handleAddPlayers}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{
            gap: 8,
          }}
        >
          <NativeIdInput currentPlayers={data} />
          <FreeSpinsInput
            stock={campaignData?.stock}
            totalAddedFreeSpins={data?.reduce((acc, player) => {
              if (player.freeSpins) {
                return acc + player.freeSpins;
              }
              return acc;
            }, 0)}
          />
          <Form.Item>
            <IconButton
              variant="contained"
              disableTouchRipple
              disableRipple
              className={classes.addButton}
              type="submit"
              disabled={data.length >= MAXIMUM_ADDED_PLAYERS}
            >
              <Add />
            </IconButton>
          </Form.Item>
        </Box>
      </Form>
      <Box
        mt={1}
        mb={4}
      >
        <EditablePlayersTable
          data={data}
          setData={setData}
          campaignData={campaignData}
        />
      </Box>
    </Box>
  );
};

export default ManualAdding;
