import React, { useEffect, useState } from 'react';
import { AutocompleteInput, useDataProvider, useTranslate } from 'react-admin';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import { TooltipForDisabledInput } from '../../../../../base/components/custom-tooltip';
import resourceSlug from '../../../../../constant/resource-slug';

const CurrencyInput = ({
  tooltipProps, ...otherProps
}) => {
  const { input: { value: brand } } = useField('brandId');
  const [currencies, setCurrencies] = useState([]);

  const translate = useTranslate();

  const hasNoBrand = !brand;

  const dp = useDataProvider();

  useEffect(() => {
    if (!brand) return;
    dp.getList(resourceSlug.BRAND, {
      pagination: {
        perPage: 1,
        page: 1,
      },
      filter: {
        'id||$eq||': brand,
      },
    }).then(({ data }) => {
      setCurrencies(data?.[0]?.currencies);
    });
  }, [brand]);

  return (
    <TooltipForDisabledInput
      title={translate('ra.message.pleaseSelectFirst', {
        smart_name: translate('resources.brand.name'),
      })}
      placement="top-start"
      showMessage={hasNoBrand}
      {...tooltipProps}
    >
      <AutocompleteInput
        label={translate('resources.campaign.fields.currency')}
        resettable
        choices={currencies}
        translateChoice={false}
        isRequired
        variant="outlined"
        {...otherProps}
      />
    </TooltipForDisabledInput>
  );
};

CurrencyInput.propTypes = {
  source: PropTypes.string,
  tooltipProps: PropTypes.object,
};

CurrencyInput.defaultProps = {
  source: 'currencyId',
  tooltipProps: {},
};

export default CurrencyInput;
