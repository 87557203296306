import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import CustomizedListActionWrapper from '../../../../../base/components/customized/CustomizedListActionWrapper';
import FilterButton from '../../../../../base/components/customized/common-list-action-buttons/FilterButton';

const PlayerUpdateBatchAction = () => {
  const t = useTranslate();

  return (
    <Box
      component={CustomizedListActionWrapper}
      width="100%"
    >
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          variant="h4"
        >
          {t('resources.campaign.player-update-batch')}
        </Typography>
        <FilterButton />

      </Box>
    </Box>
  );
};

export default PlayerUpdateBatchAction;
