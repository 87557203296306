import queryString from 'query-string';
import isObject from 'lodash/isObject';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import React from 'react';
import { Box, Tooltip } from '@material-ui/core';

// pascalCase (ex: UserClass, UserName)
export const pascalCase = str => startCase(camelCase(str)).replace(/ /g, '');

export const sanitizeMultipleWhitespace = text => text.replace(/\s{1,}/g, ' ');

export const getFilterParam = () => {
  let filters = {};
  try {
    const search = window.location.href?.split('?')?.[1];
    const searchParsed = queryString.parse(search);
    filters = JSON.parse(searchParsed?.filter);
  } catch {
    // Do nothing!
  }
  return isObject(filters) ? filters : {};
};

export const transformIdValue = (id, options = {}) => {
  const {
    prefix = '#', empty = '-',
  } = options;
  if (!id) return empty;
  if (id.length <= 12) return id;
  return <Tooltip title={id}><Box>{`${prefix}${id?.slice(0, 4)}...${id?.slice(id.length - 4)}`}</Box></Tooltip>;
};

export const getBasePath = pathName => {
  const secondSlashIndex = pathName.indexOf('/', 1);
  if (secondSlashIndex === -1) {
    return pathName;
  }
  return pathName.slice(0, secondSlashIndex);
};

export const getUserAvatar = (displayName = '', userEmail = '') => {
  if (displayName) {
    return displayName.charAt(0).toUpperCase();
  }
  if (userEmail) {
    return userEmail.charAt(0).toUpperCase();
  }
  return 'A';
};

export const truncate = (str, maxLength = 10, options = {}) => {
  const { delimiter = '...' } = options;

  return (str.length > maxLength)
    ? `${str.slice(0, maxLength - 1)}${delimiter}` : str;
};
