import React, { useContext, useEffect } from 'react';
import { Card } from '@material-ui/core';
import { BooleanField, FunctionField, ReferenceField, SelectField, TextField, useLocale, useShowController, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Cancel } from '@material-ui/icons';
import CommonShowWrapper from '../../base/components/customized/common-wrapper/CommonShowWrapper';
import { ShowSection } from '../../base/components/guesser/wealth-show.guesser';
import Labeled from '../../base/components/ra/labeled';
import { DateField } from '../../base/components/ra/fields';
import { CAMPAIGN_ISSUERS, CAMPAIGN_STATUS } from '../../constant/campaign';
import CampaignStatus from './components/CampaignStatus';
import { NavigationContext } from '../../App';
import resourceSlug from '../../constant/resource-slug';
import { UUID_REGEX } from '../../constant';
import CampaignDetailsTabs, { CAMPAIGN_TABS, CampaignTab } from './components/CampaignDetailsTabs';
import { isSystemAdmin } from '../../services/util/auth';
import { useCancelCampaign } from './components/utils';

const CampaignShow = props => {
  const isSA = isSystemAdmin();
  const t = useTranslate();
  const locale = useLocale();
  const { record: campaign } = useShowController(props);
  const isNotIssuer = campaign?.issuer === CAMPAIGN_ISSUERS.NG && !isSA;
  const hasEditPermission = (campaign?.status !== CAMPAIGN_STATUS.EXPIRED && campaign?.status !== CAMPAIGN_STATUS.CANCELED) && !isNotIssuer;

  const { cancelCampaign } = useCancelCampaign(campaign?.id);

  const { setNavigationData } = useContext(NavigationContext);
  const { pathname } = useLocation();

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: t('resources.campaign.details-title'),
        showSubLabel: false,
      },
      breadcrumbs: [
        {
          label: t('ra.page.home'),
          link: '/',
        },
        {
          label: t('resources.group.boost.name'),
        },
        {
          label: t('resources.campaign.mkt-free-spin'),
          link: `/${resourceSlug.MKT_FREE_SPINS}`,
        },
        {
          label: `#${pathname.split('/').find(path => path.match(UUID_REGEX)).slice(0, 4)}`,
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, [t, pathname, locale]);

  return (
    <CommonShowWrapper
      defaultToolbarProps={{
        hasEdit: hasEditPermission,
        extraOptions: [{
          condition: hasEditPermission,
          name: t('resources.campaign.cancel'),
          icon: <Cancel />,
          onClick: () => cancelCampaign(),
          hideLabel: true,
        }],
      }}
      {...props}
    >
      <Card>
        <CampaignDetailsTabs currentTab={CAMPAIGN_TABS.GENERAL_INFO}>
          <CampaignTab
            value={CAMPAIGN_TABS.GENERAL_INFO}
            index={CAMPAIGN_TABS.GENERAL_INFO}
          >
            <ShowSection title={t('resources.campaign.general-info')}>
              <TextField
                source="id"
                label="common.fields.id"
              />
              <TextField
                source="name"
                label="resources.campaign.fields.name"
              />
              <TextField
                source="campaignCode"
                label="resources.campaign.fields.code"
              />
              <SelectField
                source="issuer"
                label="resources.campaign.fields.issuer"
                choices={Object.values(CAMPAIGN_ISSUERS).map(issuer => ({
                  id: issuer,
                  name: t(`resources.campaign.issuer.${issuer}`),
                }))}
              />
              <Labeled label="resources.campaign.fields.start-date">
                <DateField source="startTime" />
              </Labeled>
              <Labeled label="resources.campaign.fields.end-date">
                <DateField source="endTime" />
              </Labeled>
              <Labeled label="resources.campaign.fields.status"><CampaignStatus /></Labeled>
              <ReferenceField
                reference="group"
                source="groupId"
                label="common.fields.group"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                reference="brand"
                source="brandId"
                label="common.fields.brand"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <FunctionField
                label="resources.campaign.fields.valid-period"
                render={record => t(`resources.campaign.unit-content.${record.freeSpinExpireUnit}`, {
                  smart_count: record.freeSpinExpireIn,
                })}
              />
              <TextField
                source="stock"
                label="resources.campaign.fields.stock"
              />
              <ReferenceField
                reference="currency"
                source="currencyId"
                label="common.fields.currency"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField
                source="description"
                label="resources.campaign.fields.desc"
              />
              {isSA && (
                <BooleanField
                  source="operatorUpdateEnable"
                  label="resources.campaign.fields.operator-update-enable"
                />
              )}
            </ShowSection>
          </CampaignTab>
        </CampaignDetailsTabs>

      </Card>
    </CommonShowWrapper>
  );
};

export default CampaignShow;
