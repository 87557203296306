/* eslint-disable import/no-cycle, no-use-before-define  */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FunctionField, ReferenceField, SelectField, TextField, useGetOne, useLocale, useTranslate } from 'react-admin';
import { Box, Button, Card } from '@material-ui/core';
import { keyBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Add } from '@material-ui/icons';
import { NavigationContext } from '../../../../App';
import CustomizedList from '../../../../base/components/customized/CustomizedList';
import CampaignDetailsTabs, { CAMPAIGN_TABS, CampaignTab } from '../CampaignDetailsTabs';
import { UUID_REGEX } from '../../../../constant';
import resourceSlug from '../../../../constant/resource-slug';
import { DateField } from '../../../../base/components/ra/fields';
import { CAMPAIGN_ISSUERS, CAMPAIGN_PLAYER_STATUS, CAMPAIGN_STATUS } from '../../../../constant/campaign';
import CampaignPlayerListAction from './CampaignPlayerActions';
import CampaignPlayerFilterWrapper from './CampaignPlayerFilters';
import { useTableListStyles } from '../utils';
import CommonWrapper from '../../../../base/components/reverse-integration/common-wrapper';
import CustomLinkField from '../../../../base/components/ra/fields/customLink.field';
import { transformIdValue } from '../../../../services/util/stringUtil';
import CampaignPlayerPagination from './CampaignPlayerPagination';
import UpdatePlayerDrawer from './UpdatePlayerDrawer';
import PlayerUpdateBatchTable from './PlayerUpdateBatchTable';
import { hasPermission, isSystemAdmin } from '../../../../services/util/auth';
import RemovePlayerButton from './RemovePlayerButton';
import { useAuthUser } from '../../../../base/hooks';

const PlayerFreeSpinList = props => {
  const t = useTranslate();
  const locale = useLocale();
  const classes = useTableListStyles();
  const user = useAuthUser();
  const isOperator = !!user?.group?.id;
  const { id: campaignId } = useParams();
  const { data: campaignData } = useGetOne(resourceSlug.MKT_FREE_SPINS, campaignId);
  const isSA = isSystemAdmin();
  const isNotIssuer = campaignData?.issuer === CAMPAIGN_ISSUERS.NG && !isSA;
  const hasEditPermission = hasPermission(resourceSlug.MKT_FREE_SPINS, 'update');
  const isAuthorizedOperator = isOperator && !!campaignData?.operatorUpdateEnable;

  const { setNavigationData } = useContext(NavigationContext);
  const { pathname } = useLocation();
  const canUpdatePlayers = campaignData?.status !== CAMPAIGN_STATUS.EXPIRED && campaignData?.status !== CAMPAIGN_STATUS.CANCELED
  && hasEditPermission
  && !isNotIssuer
  && (!isOperator || isAuthorizedOperator);

  const [
    playerList, currentPlayerPage,
  ] = useSelector(({ campaign }) => [campaign.playerList, campaign.currentPlayerPage]);

  const [openUpdatePlayerPopup, setOpenUpdatePlayerPopup] = useState(false);

  const players = useMemo(() => playerList[currentPlayerPage]?.data || [], [playerList, currentPlayerPage]);

  useEffect(() => {
    setNavigationData({
      pageTitleInfo: {
        label: t('resources.campaign.details-title'),
        showSubLabel: false,
      },
      breadcrumbs: [
        {
          label: t('ra.page.home'),
          link: '/',
        },
        {
          label: t('resources.group.boost.name'),
        },
        {
          label: t('resources.campaign.mkt-free-spin'),
          link: `/${resourceSlug.MKT_FREE_SPINS}`,
        },
        {
          label: `#${pathname.split('/').find(path => path.match(UUID_REGEX)).slice(0, 4)}`,
          isActive: true,
        },
      ],
    });
    return () => {
      setNavigationData({});
    };
  }, [t, pathname, locale]);

  return (
    <Card>
      <CampaignDetailsTabs currentTab={CAMPAIGN_TABS.PLAYER_FREE_SPINS}>
        <CampaignTab
          value={CAMPAIGN_TABS.PLAYER_FREE_SPINS}
          index={CAMPAIGN_TABS.PLAYER_FREE_SPINS}
        >
          <Box
            display="flex"
            flexDirection="column"
          >
            {canUpdatePlayers && (
              <Box
                px={6}
                pt={5}
                display="flex"
                justifyContent="flex-end"
                hidden={!canUpdatePlayers}
              >
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  className={classes.addPlayersBtn}
                  startIcon={<Add />}
                  disableElevation
                  onClick={() => setOpenUpdatePlayerPopup(true)}
                >
                  {t('resources.campaign.add-players')}
                </Button>
                {openUpdatePlayerPopup && (
                  <UpdatePlayerDrawer
                    onClose={() => setOpenUpdatePlayerPopup(false)}
                    campaignId={campaignId}
                    campaignData={campaignData}
                  />
                )}
              </Box>
            )}
            <CommonWrapper className={classes.wrapper}>
              <PlayerUpdateBatchTable campaign={campaignData} />
            </CommonWrapper>
            <CommonWrapper className={classes.wrapper}>
              <CustomizedList
                {...props}
                actions={<CampaignPlayerListAction />}
                filters={<CampaignPlayerFilterWrapper />}
                syncWithLocation
                hasList
                hasCreate={false}
                hasEdit={false}
                hasShow={false}
                exporter={false}
                className={classes.root}
                pagination={<CampaignPlayerPagination />}
                controllerProps={{
                  data: keyBy(players, 'id'),
                  ids: players?.map(({ id }) => id),
                }}
                sort={{
                  field: 'issuedDate',
                  order: 'DESC',
                }}
              >
                <CustomLinkField
                  source="playerId"
                  label="resources.campaign.fields.player-id"
                  href={record => `/#/${resourceSlug.PLAYER}/${record.playerId || record.id}/show`}
                  title={record => transformIdValue(record.playerId || record.id)}
                />
                <FunctionField
                  label="resources.campaign.fields.player-batch-code"
                  render={record => transformIdValue(record.batchCode, {
                    prefix: '',
                  })}
                />
                <TextField
                  source="currencyCode"
                  label="resources.currency.name"
                  sortable={false}
                  emptyText="-"
                />
                <ReferenceField
                  reference="game"
                  source="gameId"
                  label="resources.campaign.fields.game"
                  link={(record, reference) => `/${reference}/${record.gameId}/show`}
                  sortable={false}
                  emptyText="-"
                >
                  <TextField
                    source={`name.${locale}`}
                  />
                </ReferenceField>
                <DateField
                  source="issuedDate"
                  label="resources.campaign.fields.issued"
                  sortable={false}
                />
                <DateField
                  source="usedDate"
                  label="resources.campaign.fields.claimed-date"
                  sortable={false}
                  emptyText="-"
                />
                <TextField
                  source="issuedQuantity"
                  label="resources.campaign.fields.issued-quantity"
                  sortable={false}
                />
                <TextField
                  source="claimedQuantity"
                  label="resources.campaign.fields.claimed-quantity"
                  sortable={false}
                />
                <SelectField
                  source="status"
                  label="resources.campaign.fields.status"
                  choices={Object.values(CAMPAIGN_PLAYER_STATUS).map(status => ({
                    id: status,
                    name: t(`resources.campaign.player-status.${status}`),
                  }))}
                  sortable={false}
                />
                {canUpdatePlayers && (
                  <FunctionField
                    label="ra.field.action"
                    render={record => (
                      <RemovePlayerButton player={record} />
                    )}
                  />
                )}
              </CustomizedList>
            </CommonWrapper>
          </Box>
        </CampaignTab>
      </CampaignDetailsTabs>
    </Card>
  );
};

export default PlayerFreeSpinList;
