/* eslint-disable no-use-before-define */
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import React from 'react';
import { useDataProvider, useListContext, useTranslate } from 'react-admin';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useRemoveCampaignPlayer, useTableListStyles } from '../utils';
import { hasPermission } from '../../../../services/util/auth';
import resourceSlug from '../../../../constant/resource-slug';
import { showConfirmDialog } from '../../../../services/redux/app/app.actions';
import { CAMPAIGN_PLAYER_STATUS } from '../../../../constant/campaign';

const RemovePlayerButton = ({ player }) => {
  const t = useTranslate();
  const classes = useTableListStyles();
  const { id: campaignId } = useParams();
  const hasEditPermission = hasPermission(resourceSlug.MKT_FREE_SPINS, 'update');
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const {
    filterValues, currentSort, perPage,
  } = useListContext();

  const {
    removePlayer, isRemoving,
  } = useRemoveCampaignPlayer(campaignId);

  const onRemoveConfirm = () => {
    dispatch(
      showConfirmDialog({
        isOpen: true,
        title: 'resources.campaign.remove-player-title',
        content: 'resources.campaign.remove-player-desc',
        onConfirm: () => onRemove(player),
      }),
    );
  };

  const onRemove = async () => {
    removePlayer(player.playerId, player.batchId, {
      onSuccess: async () => {
        await dataProvider.getList(`${resourceSlug.MKT_FREE_SPINS}/${campaignId}/players`,
          {
            pagination: {
              perPage,
              cachedPage: 1,
            },
            sort: currentSort,
            filter: {
              ...filterValues,
            },
          });
      },
    });
  };

  return (
    <Tooltip title={t('resources.campaign.remove')}>
      <Box>
        <IconButton
          className={classes.removeButton}
          disabled={player.status !== CAMPAIGN_PLAYER_STATUS.PENDING || isRemoving || !hasEditPermission}
          onClick={() => onRemoveConfirm(player)}
        >
          <RemoveCircle />
        </IconButton>
      </Box>

    </Tooltip>
  );
};

export default RemovePlayerButton;
