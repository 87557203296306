import { Box, Tooltip, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslate } from 'react-admin';
import { Form, InputNumber } from 'antd';
import { Info } from '@material-ui/icons';
import { CAMPAIGN_UNIT_EXPIRE_IN, MAX_VALID_PERIOD_IN_MONTHS } from '../../../../../constant/campaign';
import AntdInput from '../../../../../base/components/antd/AntdInput';
import { formatNumber } from '../../../../../services/util/formatNumber';

const useStyles = makeStyles({
  infoIcon: {
    width: 14,
    height: 14,
  },
});

const FreeSpinExpireIn = ({ initialValue }) => {
  const t = useTranslate();
  const classes = useStyles();

  return (
    <Form.Item
      name="freeSpinExpireIn"
      rules={[{
        required: true,
        message: t('ra.validation.required'),
      },
      ({ getFieldValue }) => ({
        validator() {
          const freeSpinExpireUnit = getFieldValue('freeSpinExpireUnit');
          const currentValue = getFieldValue('freeSpinExpireIn');
          const maxValue = freeSpinExpireUnit === CAMPAIGN_UNIT_EXPIRE_IN.DAYS ? 30 * MAX_VALID_PERIOD_IN_MONTHS : 30 * MAX_VALID_PERIOD_IN_MONTHS * 24;

          if (currentValue > maxValue) {
            return Promise.reject(new Error(t('resources.campaign.validation.max-value', {
              max: formatNumber('en', maxValue),
            })));
          }
          return Promise.resolve();
        },
      })]}
      initialValue={initialValue}
    >
      <AntdInput
        label={(
          <Box
            display="flex"
            alignItems="center"
            sx={{
              gap: 4,
            }}
          >
            {t('resources.campaign.fields.valid-period')}
            <Tooltip
              placement="top"
              title={t('resources.campaign.fields.issued-date-tooltip')}
            >
              <Info className={classes.infoIcon} />
            </Tooltip>
          </Box>
        )}
        wrapperProps={{
          minWidth: 150,
        }}
      >
        <InputNumber
          step={1}
          precision={0}
          placeholder={t('resources.campaign.fields.valid-period')}
        />
      </AntdInput>
    </Form.Item>
  );
};

export default FreeSpinExpireIn;
