import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataProvider, useListContext } from 'react-admin';
import { useParams } from 'react-router-dom';
import InfinitePagination from '../../../../../base/components/ra/list/InfinitePagination';
import resourceSlug from '../../../../../constant/resource-slug';
import { sanitizeObject } from '../../../../../services/util';
import { cacheCampaignPlayerBatchByPage, updateCurrentCampaignPlayerBatchPage } from '../../../../../services/redux/campaign/campaign.action';

const PlayerUpdateBatchPagination = () => {
  const [
    playerBatchList, currentPlayerBatchPage,
  ] = useSelector(({ campaign }) => [campaign.playerBatchList, campaign.currentPlayerBatchPage]);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const {
    filterValues, currentSort, perPage,
  } = useListContext();
  const { id } = useParams();

  const disableNext = useMemo(() => !playerBatchList?.[currentPlayerBatchPage]?.nextPagination, [currentPlayerBatchPage, playerBatchList]);

  const handlePageChange = useCallback(
    async newPage => {
      try {
        if (!playerBatchList[newPage]) {
          const nextPagination = playerBatchList?.[newPage - 1]?.nextPagination;
          const data = await dataProvider.getList(`${resourceSlug.MKT_FREE_SPINS}/${id}/player-batch`,
            {
              pagination: {
                perPage,
                cachedPage: newPage,
              },
              sort: currentSort,
              filter: {
                ...filterValues,
                q: sanitizeObject({
                  nextPagination: JSON.stringify(nextPagination),
                }),
              },
            });

          dispatch(
            cacheCampaignPlayerBatchByPage({
              data,
              page: newPage,
            }),
          );
        }
        dispatch(updateCurrentCampaignPlayerBatchPage(newPage));
      } catch (error) {
        // Do nothing
      }
    },
    [playerBatchList, currentPlayerBatchPage],
  );

  return (
    <InfinitePagination
      disableNext={disableNext}
      disablePrev={currentPlayerBatchPage === 1}
      onNextPage={() => {
        handlePageChange(currentPlayerBatchPage + 1);
      }}
      onPrevPage={() => {
        handlePageChange(currentPlayerBatchPage - 1);
      }}
      currentPage={currentPlayerBatchPage}
    />
  );
};

export default PlayerUpdateBatchPagination;
