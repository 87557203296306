import { MenuItem, Select } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { CAMPAIGN_UNIT_EXPIRE_IN } from '../../../../../constant/campaign';

const FreeSpinExpireUnit = props => {
  const t = useTranslate();

  const issuedExpirationChoices = useMemo(
    () => Object.values(CAMPAIGN_UNIT_EXPIRE_IN).map(unit => ({
      id: unit,
      name: t(`resources.campaign.unit.${unit}`),
    })),
    [t],
  );

  return (
    <Select
      variant="outlined"
      label=""
      {...props}
    >
      {issuedExpirationChoices.map(item => (
        <MenuItem
          key={item.id}
          value={item.id}
        >
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default FreeSpinExpireUnit;
