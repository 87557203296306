/* eslint-disable import/no-cycle */
import { FormDataConsumer, SimpleForm, Toolbar, useTranslate } from 'react-admin';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import CampaignStepper from './CampaignStepper';
import BasicInfoStep from './BasicInfoStep';
import PreviewStep from './PreviewStep';
import { validateCampaign } from '../utils';
import { showConfirmDialog } from '../../../../services/redux/app/app.actions';
import resourceSlug from '../../../../constant/resource-slug';
import useSetting from '../../../../base/hooks/useSetting';

const CampaignStepContext = createContext({});

export const useCampaignSteps = () => {
  const steps = useContext(CampaignStepContext);

  if (steps === null) {
    throw new Error('Make sure to use `CampaignStepContext` before using the steps.');
  }

  return steps;
};

const CampaignCreateUpdateForm = ({
  clonedRecord, ...props
}) => {
  const t = useTranslate();
  const settings = useSetting();
  const [activeStep, setActiveStep] = useState(0);
  const {
    saving, save, record: initialRecord,
  } = props;

  const record = clonedRecord || initialRecord;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <SimpleForm
      toolbar={null}
      validate={values => validateCampaign(values, t, settings)}
      mutators={{
        triggerValidation([[fieldsToValidate, form]], state, { changeValue }) {
          // Trigger validation
          fieldsToValidate.forEach(fieldName => {
            changeValue(state, fieldName, n => n);
          });

          const errorFieldNames = Object.entries(state.formState.errors || {})
            .filter(([key, value]) => fieldsToValidate.includes(key) && (!Array.isArray(value) || value.find(v => Object.keys(v || {}).length)))
            .map(([key]) => key);

          if (errorFieldNames.length) {
            form.submit(); // To trigger validation
          }

          return !errorFieldNames.length;
        },
      }}
      {...props}
    >
      <CampaignStepContext.Provider
        value={{
          activeStep,
          handleNext,
          handleBack,
        }}
      >
        <CampaignCreateUpdateFields record={record} />
        <CustomizedToolbar
          campaignId={record?.id}
          save={save}
          saving={saving}
        />
      </CampaignStepContext.Provider>
    </SimpleForm>
  );
};

const CampaignCreateUpdateFields = ({ record }) => {
  const form = useForm();
  const { activeStep } = useCampaignSteps();

  useEffect(() => {
    if (isEmpty(record)) {
      return;
    }

    form.reset(record);
  }, [record]);

  return (
    <Box
      px={{
        xs: '16px',
        md: '28px',
      }}
    >
      <CampaignStepper />
      {
        {
          0: <BasicInfoStep
            isEditForm={!!record?.id}
            initialValue={record}
          />,
          1: <PreviewStep isEditForm={!!record?.id} />,
        }[activeStep]
      }
    </Box>
  );
};

const CustomizedToolbar = ({
  campaignId, save, saving,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useTranslate();
  const {
    activeStep, handleBack, handleNext,
  } = useCampaignSteps();
  const form = useForm();
  const {
    dirty, values: { campaignType },
  } = useFormState();
  const stepFields = useMemo(() => ({
    0: ['campaignName', 'campaignCode', 'brandId', 'groupId', 'issuer', 'expDate', 'description', 'stock', 'currencyId', 'freeSpinExpireIn', 'freeSpinExpireUnit'],
    1: [],
  }[activeStep]), [activeStep, campaignType]);

  const [isDirty, setIsDirty] = useState({
    0: false,
    1: false,
  });

  useEffect(() => {
    if (activeStep === 1) return;
    setIsDirty(currentState => ({
      ...currentState,
      [activeStep]: dirty,
    }));
  }, [dirty, activeStep]);

  const PreviousButton = (
    <Button
      onClick={handleBack}
      disabled={activeStep === 0 || saving}
      variant="contained"
      disableElevation
    >
      {t('ra.action.previous')}
    </Button>
  );

  const NextButton = (
    <Button
      onClick={() => {
        if (form.mutators.triggerValidation([stepFields, form])) { handleNext(); }
      }}
      variant="contained"
      disableElevation
      disabled={activeStep === 1}
    >
      {t('ra.action.next')}
    </Button>
  );

  const CancelButton = (
    <Button
      onClick={() => {
        dispatch(
          showConfirmDialog({
            isOpen: true,
            title: 'resources.campaign.cancel-title',
            content: 'resources.campaign.cancel-desc',
            onConfirm: () => history.replace(`/${resourceSlug.MKT_FREE_SPINS}/${campaignId ? `${campaignId}/show` : ''}`),
          }),
        );
      }}
      variant="outlined"
      disabled={saving}
    >
      {t('ra.action.cancel')}
    </Button>
  );

  const SaveButton = (
    <FormDataConsumer>
      {({ formData }) => (
        <Button
          disabled={saving || (!!campaignId && Object.values(isDirty).every(item => !item))}
          color="primary"
          variant="contained"
          disableElevation
          onClick={() => save(formData)}
        >
          {t(campaignId ? 'ra.action.update' : 'ra.action.save')}
        </Button>
      )}
    </FormDataConsumer>
  );

  return (
    <Box
      component={Toolbar}
      display="flex"
      justifyContent="space-between"
      mx="-16px"
      mb="-16px"
      sx={{
        px: {
          xs: '24px !important',
          md: '44px !important',
        },
      }}
    >
      <Box
        display="flex"
        gap={8}
      >
        {campaignId ? (
          <>
            {PreviousButton}
            {NextButton}
          </>
        ) : (
          CancelButton
        )}
      </Box>
      <Box
        display="flex"
        gap={8}
      >
        {campaignId ? (
          <>
            {CancelButton}
            {SaveButton}
          </>
        ) : (
          <>
            {PreviousButton}
            {activeStep !== 1 ? (
              NextButton
            ) : (
              SaveButton
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default CampaignCreateUpdateForm;
