/* eslint-disable */
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(({
  input:{
    width: '100%'
  }
}));

const AntdInput = ({
  children, label, wrapperProps, ...otherProps
}) => {
  const classes = useStyles();
  return (
    <Box
      position="relative"
      mt={2}
      {...wrapperProps}
    >
      {label && (
        <Box
          component="label"
          px="6px"
          top="-7px"
          left="9px"
          fontSize={14}
          lineHeight={1}
          fontFamily="Normal"
          sx={{
            color: '#00000099',
          }}
          position="absolute"
          zIndex={1}
          bgcolor="white"
        >
          {label}
        </Box>
      )}
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return child;
        }
        return React.cloneElement(child, {
          ...otherProps,
          className: clsx(classes.input, otherProps?.className),
        });
      })}
    </Box>
  );
};

export default AntdInput;
