import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { SelectInput } from '../../../../../base/components/ra/inputs';
import { CAMPAIGN_ISSUERS } from '../../../../../constant/campaign';
import { insertIf } from '../../../../../services/util';
import { isSystemAdmin } from '../../../../../services/util/auth';

const IssuerInput = props => {
  const t = useTranslate();
  const isSA = isSystemAdmin();

  const issuerChoices = useMemo(
    () => [
      ...insertIf(isSA, {
        id: CAMPAIGN_ISSUERS.NG,
        name: t(`resources.campaign.issuer.${CAMPAIGN_ISSUERS.NG}`),
      }),
      {
        id: CAMPAIGN_ISSUERS.OPERATOR,
        name: t(`resources.campaign.issuer.${CAMPAIGN_ISSUERS.OPERATOR}`),
      },
    ],
    [t, isSA],
  );

  return (
    <SelectInput
      source="issuer"
      label="resources.campaign.fields.issuer"
      choices={issuerChoices}
      isRequired
      fullWidth
      defaultValue={issuerChoices.length === 1 ? issuerChoices[0].id : undefined}
      {...props}
    />
  );
};

export default IssuerInput;
