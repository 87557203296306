/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocale, useTranslate } from 'react-admin';
import { Box, Checkbox, ListItemText, MenuItem, TextField, Tooltip, makeStyles } from '@material-ui/core';
import { Form } from 'antd';
import { intersection, keyBy } from 'lodash';
import { useAvailableGames, useSelectedAvailableGameValue } from '../../utils';
import MuiSelect from '../../../../../base/components/mui/MuiSelect';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  inputRoot: {
    width: '100%',
  },
  input: {
    "&[class*='inputRoot']": {
      paddingBottom: 1,
      paddingTop: 1,
      paddingLeft: 14
    },
    "& [class*='notchedOutline']>legend": {
      width: 'fit-content',
      maxWidth: 86
    }
  },
  gameList: {
    display: 'grid !important',
    gridTemplateColumns: 'repeat(2, 1fr)',
    overflowY: "scroll",
    padding: 0,
    gap: 4
  },
  gameListWrapper: {
    borderRadius: 8,
    padding: 12,
    maxHeight: 420
  },
  gameListPopper: {
    width: "600px !important",
  },
  gameItem: {
    display: "flex",
    alignItems: "center",
    '&:hover': {
      borderRadius: 8,
    },
    '&>*[class*="MuiCheckbox"]': {
      padding: 6,
    },
    "&[class*='selected']": {
      background: 'transparent',
      color: theme.palette.primary.main,
    },
    "& *[class*='checked']": {
      color: theme.palette.primary.main,
    },
  },
  option: {
    padding: 8,
    borderRadius: 8
  },
  optionLabel: {
    "&>*": {
      fontSize: 14
    }
  },
  label: {
    "&[class*='shrink']": {
      transform: "translate(14px, -7px)"
    }
  },
  required: {
    transform: "scale(0.75)",
    display: "inline-block",
    marginLeft: 3
  }
}));

const GameSelection = ({
  form, brandId, currencyId,
}) => {
  const t = useTranslate();
  const [intersectedBetAmounts, setIntersectedBetAmounts] = useState([]);
  const { games, isLoading: isLoadingGames } = useAvailableGames(brandId, currencyId);
  const selectedGames = Form.useWatch('games', form);
  const { setValue } = useSelectedAvailableGameValue();

  const gameObject = useMemo(() => keyBy(games, value => setValue(value)), [games]);

  useEffect(() => {
    const betAmounts = selectedGames?.reduce((list, game) => {
      if(gameObject[game]?.betAmounts?.length) {
        list.push(gameObject[game].betAmounts)
      }
      return list
    }, []);
    if (!betAmounts?.length) {
      setIntersectedBetAmounts([])
      return;
    }
    setIntersectedBetAmounts(intersection(...betAmounts));
  }, [selectedGames, gameObject]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      sx={{
        gap: 24,
      }}
    >
      <Box flexGrow={1}>
        <Form.Item
          name="games"
          rules={[{
            type: 'array',
            min: 1,
            message: t('ra.validation.required'),
          }]}
          initialValue={[]}
          validateStatus="error"
        >
          <GameListSelect
            games={games}
            intersectedBetAmounts={intersectedBetAmounts}
            isLoading={isLoadingGames}
          />
        </Form.Item>
      </Box>
      <BetAmountSelect
        betAmounts={intersectedBetAmounts}
        form={form}
      />
    </Box>
  );
};

const GameListSelect = ({
  value: games, games: allGames, intersectedBetAmounts, onChange, isLoading, ...otherProps
}) => {
  const t = useTranslate();
  const locale = useLocale();
  const classes = useStyles();
  const {
    setValue,
  } = useSelectedAvailableGameValue();

  const gameObject = useMemo(() => keyBy(allGames, value => setValue(value)), [allGames]);

  const renderSelectedGames = useCallback(selected => {
    if (selected?.length < 3) return selected.map(item => gameObject?.[item]?.name?.[locale])?.join(', ');
    const secondSelected = selected.slice(0, 1).map(item => gameObject?.[item]?.name?.[locale])?.join(', ');

    return `${secondSelected},  ${t('resources.campaign.selected-games', {
      totalSelected: selected.length - 1,
    })}`;
  }, [gameObject]);

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      value={games}
      renderValue={renderSelectedGames}
      fullWidth
      className={classes.inputRoot}
      classes={{
        popper: classes.gameListPopper,
        paper: classes.gameListWrapper,
        listbox: classes.gameList,
        option: classes.option,
        inputRoot: classes.input,
      }}
      loading={isLoading}
      onChange={(_, games, reason, details) => {
        if (reason === "clear") {
          onChange([])
          return
        }
        const newGame = setValue(details?.option)
        const previousGames = games.filter(game => typeof game === "string")
        const isSelected = previousGames.includes(newGame)

        if (isSelected) {
          onChange(previousGames.filter(game => game !== newGame))
        } else {
          onChange([...previousGames, newGame])
        }
      }}
      options={allGames}
      getOptionLabel={(option) => option.name?.[locale]}
      getOptionDisabled={(game) => (!intersection(game.betAmounts, intersectedBetAmounts)?.length && !!intersectedBetAmounts?.length) || !game.betAmounts?.length}
      renderOption={game => <Box
        className={classes.gameItem}
        key={setValue(game)}
        disabled={(!intersection(game.betAmounts, intersectedBetAmounts)?.length && !!intersectedBetAmounts?.length) || !game.betAmounts?.length}
      >
        <Checkbox size='small' checked={!!games?.find(item => item === setValue(game))} />
        <ListItemText className={classes.optionLabel} primary={game.name?.[locale]} />
      </Box>}
      renderInput={(params) => (
        <TextField
          {...params}
          label={<div>{t('resources.campaign.fields.game-list')}<span className={classes.required}>*</span></div>}
          variant="outlined"
          autoComplete="off" // disable autocomplete and autofill
          inputProps={{ ...params.inputProps, placeholder: games?.length ? '' : t('resources.campaign.fields.game-list-placeholder') }}
          InputLabelProps={{ shrink: true, className: classes.label }}
        />
      )}
      renderTags={(selected) => {
        if (selected?.length < 3) return selected.map(item => gameObject?.[item]?.name?.[locale])?.join(', ');
        const secondSelected = selected.slice(0, 1).map(item => gameObject?.[item]?.name?.[locale])?.join(', ');

        return `${secondSelected},  ${t('resources.campaign.selected-games', { totalSelected: selected.length - 1 })}`;
      }}
      {...otherProps}
    />
  );
};

const BetAmountSelect = ({
  betAmounts, form,
}) => {
  const classes = useStyles();
  const games = Form.useWatch('games', form);
  const hasGame = !!games?.length;
  const t = useTranslate();

  useEffect(() => {
    form?.change?.('betAmount', undefined);
  }, [games]);

  return (
    <Tooltip
      title={!hasGame ? t('ra.message.pleaseSelectFirst', {
        smart_name: t('resources.campaign.fields.game-list'),
      }) : ''}
    >
      <Box width={200}>
        <Form.Item
          name="betAmount"
          rules={[{
            required: true,
            message: t('ra.validation.required'),
          }]}
        >
          <MuiSelect
            label={t('resources.campaign.fields.bet-amount')}
            disabled={!hasGame}
            className={classes.common}
            required
          >
            {betAmounts?.map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {value}
              </MenuItem>
            ))}
          </MuiSelect>
        </Form.Item>
      </Box>
    </Tooltip>

  );
};

export default GameSelection;
